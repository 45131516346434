export const cpfValidation = (value) => {
	value = value.replace(/[^\d]+/g,'');
	if (value.length < 11 || value.length > 11) {
		return true
	}
	let sum = 0;
	if (
		value === '00000000000' || value === '11111111111' || value === '22222222222' ||
		value === '33333333333' || value === '44444444444' || value === '55555555555' ||
		value === '66666666666' || value === '77777777777' || value === '88888888888' ||
		value === '99999999999' 
		) {
		return true;
	}

	for (let i=1; i <= 9; i++) {
		sum = sum + parseInt(value.substring(i-1,i)) * (11- i);
	}

	let remainder = (sum * 10) % 11;

	if (remainder === 10 || remainder === 11) {
		remainder = 0;
	}

	if (remainder !== parseInt(value.substring(9,10))) {
		return true;
	}

	sum = 0;
	for (let i=1; i<=10;i++) {
		sum = sum + parseInt(value.substring(i-1,i)) *(12 - i);
	}

	remainder = (sum * 10) % 11;

	if (remainder === 10 || remainder === 11) {
		remainder = 0;
	}

	if (remainder !== parseInt(value.substring(10,11))) {
		return true;
	}

	return false;
}

export const cnpjValidation = (value) => {
	value = value.replace(/[^\d]+/g,'');
	if (value.length < 14 || value.length > 14) {
		return true;
	}

	if (
		value === '00000000000000' || value === '11111111111111' || value === '22222222222222' ||
		value === '33333333333333' || value === '44444444444444' || value === '55555555555555' ||
		value === '66666666666666' || value === '77777777777777' || value === '88888888888888' ||
		value === '99999999999999'
	) {
		return true;
	}

	let size = value.length - 2;
	let numbers = value.substring(0,size);
	let digits = value.substring(size);

	let sum = 0;

	let factor = size - 7;

	for (let i = size; i >= 1; i--) {
		sum += numbers.charAt(size - i) * factor--;
		if (factor < 2) {
			factor = 9;
		}
	}

	let result = sum % 11 < 2 ? 0 : 11 - sum % 11;

	if (result !== digits.charAt(0)) {
		return true
	}

	size = size + 1;
	numbers = value.substring(0,size);
	sum = 0;
	factor = size - 7;
	for (let i = size; i>= 1; i--) {
		sum += numbers.charAt(size - i) * factor--;
		if (factor < 2) {
			factor = 9;
		}
	}

	result = sum % 11 < 2 ? 0 : 11 - sum % 11;

	if (result !== digits.charAt(1)) {
		return true
	}

	return false

}

const emailValidation = (value) => {
	return !/\S+@\S+/.test(value)
}

const nameValidation = (value) => {
	return value.length < 3
}

const phoneValidation = (value) => {
	return !/^[1-9]{1}[0-9]{10}$/.test(value)
}

export const inputValidation = (value, type) => {
	const functions = {
		name: nameValidation,
		email: emailValidation,
		cpf: cpfValidation,
		mobilePhone: phoneValidation,
	}

	return functions[type](value)
}

export const hasValidation = (type) => {
	const validations = ['name', 'email', 'cpf', 'mobilePhone'];

	if (validations.includes(type)) {
		return true
	} else {
		return false
	}
}